<template>
  <div v-loading="fetching" class="cg-fixed cg-z-10 cg-top-180 cg-right-200">
    <div class="cg-mb-20 cg-pl-10">
      <div class="cg-mb-10">
        <span class="cg-mr-20">效果预览</span>
        <el-button v-if="showUpdateButton" @click="onClickUpdatePreview">更新预览</el-button>
      </div>
      <div class="cg-text-14px cg-text-hex-999">
        提示:当前效果仅供参考，不同机型实际效果有所差异
      </div>
    </div>
    <div class="_iframe-wrap">
      <iframe
        ref="iframe"
        class="cg-rounded-20"
        width="100%"
        height="100%"
        frameborder="0"
        :src="`${iframeSrc}`"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { ADMIN_H5_ADDRESS } from "@/config";
import { companyChargePlanApi } from "@/services/apis/company/charge-plan";

export default {
  props: {
    showUpdateButton: Boolean,
    pricingOptionInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    this.iframe = null;
    return {
      iframeSrc: ADMIN_H5_ADDRESS,
    };
  },

  computed: {
    ...mapState("loading", ["effects"]),

    fetching() {
      return this.effects[companyChargePlanApi.previewEffectKey];
    },
  },

  watch: {
    pricingOptionInfo: {
      handler() {
        this.postPreviewData();
      },
      immediate: true,
    },
  },

  mounted() {
    this.initIframe();
  },

  methods: {
    initIframe() {
      this.iframe = this.$refs.iframe;
      this.iframe.onload = () => {
        this.postPreviewData();
      };
    },

    postPreviewData() {
      if (Object.keys(this.pricingOptionInfo).length && this.iframe && this.iframe.contentWindow) {
        this.iframe.contentWindow.postMessage({ pricingOptionInfo: this.pricingOptionInfo }, "*");
      }
    },

    onClickUpdatePreview() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
._iframe-wrap {
  width: 370px;
  height: 640px;
  padding: 48px 13px 25px;
  background: url("~@/assets/images/iPhone13.png");
  background-size: cover;
  overflow: hidden;
}
</style>
